<template>
  <div class="va-row dashboard-info-widgets">
    <div class="flex md6 xl6 xs12">
      <vuestic-widget class="info-widget">
        <div class="info-widget-inner">
          <div class="stats">
            <div class="pl-2 stats-number">
              <i class="sidebar-menu-item-icon vuestic-icon vuestic-icon-statistics stats-icon" />
              {{areaTamanho}}
            </div>
            <div class="stats-title">Áreas</div>
          </div>
        </div>
      </vuestic-widget>
    </div>
    <div class="flex md6 xl6 xs12">
      <vuestic-widget class="info-widget">
        <div class="info-widget-inner">
          <div class="stats">
            <div class="pl-2 stats-number">
              <i class="sidebar-menu-item-icon fa fa-building stats-icon" />
              {{empresaTamanho}}
            </div>
            <div class="stats-title">Empresas</div>
          </div>
        </div>
      </vuestic-widget>
    </div>
    <div class="flex md6 xl6 xs12">
      <vuestic-widget class="info-widget">
        <div class="info-widget-inner">
          <div class="stats">
            <div class="pl-2 stats-number">
              <i class="sidebar-menu-item-icon vuestic-icon vuestic-icon-ui-elements stats-icon" />
              {{funcionarioTamanho}}
            </div>
            <div class="stats-title">Funcionários</div>
          </div>
        </div>
      </vuestic-widget>
    </div>
    <div class="flex md6 xl6 xs12">
      <vuestic-widget class="info-widget">
        <div class="info-widget-inner">
          <div class="stats">
            <div class="pl-2 stats-number">
              <i class="sidebar-menu-item-icon vuestic-icon vuestic-icon-auth stats-icon" />
              {{ferrmentaTamanho}}
            </div>
            <div class="stats-title">Ferramentas</div>
          </div>
        </div>
      </vuestic-widget>
    </div>
    <div class="flex md6 xl6 xs12">
      <vuestic-widget class="info-widget">
        <div class="info-widget-inner">
          <div class="stats">
            <div class="pl-2 stats-number">
              <i class="sidebar-menu-item-icon vuestic-icon vuestic-icon-maps stats-icon" />
              {{indicadorTamanho}}
            </div>
            <div class="stats-title">Indicadores</div>
          </div>
        </div>
      </vuestic-widget>
    </div>
    <div class="flex md6 xl6 xs12">
      <vuestic-widget class="info-widget">
        <div class="info-widget-inner">
          <div class="stats">
            <div class="pl-2 stats-number">
              <i class="sidebar-menu-item-icon vuestic-icon vuestic-icon-files stats-icon" />
              {{checklistTamanho}}
            </div>
            <div class="stats-title">Checklists</div>
          </div>
        </div>
      </vuestic-widget>
    </div>

  </div>
</template>

<script>
export default {
  name: 'dashboard-info-widgets',
  data() {
    return {
      areaTamanho: 0,
      empresaTamanho: 0,
      funcionarioTamanho: 0,
      indicadorTamanho: 0,
      ferrmentaTamanho: 0,
      checklistTamanho: 0,
    };
  },
  created() {
    this.getAreas();
    this.getEmpresa();
    this.getFerramentas();
    this.getIndicadores();
    this.getFuncionarios();
    this.getChecklist();
  },
  methods: {
    getAreas() {
      this.$axios.get('/api/area.json').then((res) => {
        this.areaTamanho = res.data.length;
      });
    },
    getEmpresa() {
      this.$axios.get('/api/empresa.json').then((res) => {
        this.empresaTamanho = res.data.length;
      });
    },
    getFerramentas() {
      this.$axios.get('/api/ferramenta.json').then((res) => {
        this.ferrmentaTamanho = res.data.length;
      });
    },
    getIndicadores() {
      this.$axios.get('/api/indicador.json').then((res) => {
        this.indicadorTamanho = res.data.length;
      });
    },
    getFuncionarios() {
      this.$axios.get('/api/funcionario.json').then((res) => {
        this.funcionarioTamanho = res.data.length;
      });
    },
    getChecklist() {
      this.$axios.get('/api/checklist.json').then((res) => {
        this.checklistTamanho = res.data.length;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.stats-number,
.stats-title {
  line-height: 1;
}

.info-widget-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;

  &.has-chart {
    justify-content: space-between;
  }

  .stats {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.stats-number {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 2.625rem;
  margin-bottom: 0.5rem;

  .stats-icon {
    font-size: 1.5625rem;
    position: absolute;
    top: 0.625rem;
    left: -1.25rem;

    &.icon-wide {
      left: -1.875rem;
    }
  }
}
</style>
